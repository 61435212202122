import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import remark from 'remark'
import remarkHtml from 'remark-html'
// We can't use `recommended` or IE11 breaks!
// import recommended from 'remark-preset-lint-recommended'

import Layout from '../components/Layout'
import BannerCards from '../components/BannerCards'
import BulletList from '../components/BulletList'
import ButtonGroup from '../components/ButtonGroup'
import SEO from '../components/seo'

export const IndexPageTemplate = ({
  image,
  bgimage,
  bannerinfo,
  heading,
  seo,
  description,
  descriptionbullets,
  contactpanel,
}) => (
  <div className="">
    {seo || ''}
    <div className="banner-image--container">
      <Img
        className="full-width-image margin-top-0 banner-image"
        fluid={bgimage}
        backgroundColor={`#1d3d6d`}
      />
    </div>
    <div className="content-background">
      <div className="banner-card__container">
        <BannerCards
          rowItems={bannerinfo.cards}
          className={'is-desktop is-hidden-touch'}
        />
      </div>

      <section className="section section--gradient section--home">
        <div className="container">
          <div className="card card-content content-background--card">
            <div className="content">
              <div className="columns is-desktop">
                <div className="column is-8-desktop is-12-mobile">
                  <h3 className="main-heading has-text-weight-bold">
                    {heading}
                  </h3>
                  <div
                    className="is-size-5 main-body"
                    dangerouslySetInnerHTML={{
                      __html: remark()
                        .use(remarkHtml)
                        .processSync(description)
                        .toString(),
                    }}
                  />
                  <BulletList
                    bullets={descriptionbullets.bullets}
                    istwocolumns={true}
                  />
                </div>

                <div className="column is-4-desktop is-12-mobile">
                  <ButtonGroup buttons={contactpanel.websitelinks} />
                  <h3 className="has-text-weight-bold is-size-5">
                    {contactpanel.identifiers.heading}
                  </h3>
                  <p className="is-size-5">
                    {contactpanel.identifiers.duns}
                    <br />
                    {contactpanel.identifiers.cage}
                  </p>
                  <div>
                    <h3 className="has-text-weight-bold is-size-5">
                      {contactpanel.contactdetails.heading}
                    </h3>
                    <p className="is-size-5">
                      <em>
                        {contactpanel.contactdetails.headquarters.heading}
                      </em>
                      <br />
                      {contactpanel.contactdetails.headquarters.street}
                      <br />
                      {contactpanel.contactdetails.headquarters.citystatezip}
                      <br />
                      <a
                        href={
                          'tel:' +
                          contactpanel.contactdetails.headquarters.office
                        }
                        className="is-size-5"
                      >
                        <FontAwesomeIcon
                          className="fa-icon"
                          size="xs"
                          icon="phone-alt"
                        />
                        {contactpanel.contactdetails.headquarters.office}
                      </a>
                      <br />
                    </p>
                    <p className="is-size-5">
                      <em>{contactpanel.contactdetails.pocgroupone.heading}</em>
                      <br />
                      {contactpanel.contactdetails.pocgroupone.name}
                      <br />
                      {contactpanel.contactdetails.pocgroupone.title}
                      <br />
                      <a
                        href={
                          'tel:' + contactpanel.contactdetails.pocgroupone.phone
                        }
                        className="is-size-5"
                      >
                        <FontAwesomeIcon
                          className="fa-icon"
                          size="xs"
                          icon="phone-alt"
                        />
                        {contactpanel.contactdetails.pocgroupone.phone}
                      </a>
                      <br />
                      <a
                        href={
                          'mailto:' +
                          contactpanel.contactdetails.pocgroupone.email
                        }
                        className="is-size-5"
                      >
                        <FontAwesomeIcon
                          className="fa-icon"
                          size="xs"
                          icon="envelope"
                        />
                        {contactpanel.contactdetails.pocgroupone.email}
                      </a>
                    </p>
                    <p className="is-size-5">
                      <em>{contactpanel.contactdetails.pocgrouptwo.heading}</em>
                      <br />
                      {contactpanel.contactdetails.pocgrouptwo.name}
                      <br />
                      {contactpanel.contactdetails.pocgrouptwo.title}
                      <br />
                      <a
                        href={
                          'tel:' + contactpanel.contactdetails.pocgrouptwo.phone
                        }
                        className="is-size-5"
                      >
                        <FontAwesomeIcon
                          className="fa-icon"
                          size="xs"
                          icon="phone-alt"
                        />
                        {contactpanel.contactdetails.pocgrouptwo.phone}
                      </a>
                      <br />
                      <a
                        href={
                          'mailto:' +
                          contactpanel.contactdetails.pocgrouptwo.email
                        }
                        className="is-size-5"
                      >
                        <FontAwesomeIcon
                          className="fa-icon"
                          size="xs"
                          icon="envelope"
                        />
                        {contactpanel.contactdetails.pocgrouptwo.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
)

IndexPageTemplate.propTypes = {
  seo: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bannerinfo: PropTypes.shape({
    cards: PropTypes.array,
  }),
  heading: PropTypes.string,
  description: PropTypes.string,
  descriptionbullets: PropTypes.shape({
    bullets: PropTypes.array,
  }),
  contactpanel: PropTypes.shape({
    websitelinks: PropTypes.array,
    identifiers: PropTypes.shape({
      heading: PropTypes.string,
      duns: PropTypes.string,
      cage: PropTypes.string,
    }),
    contactdetails: PropTypes.shape({
      heading: PropTypes.string,
      headquarters: PropTypes.shape({
        heading: PropTypes.string,
        street: PropTypes.string,
        citystatezip: PropTypes.string,
        office: PropTypes.string,
      }),
      pocgroupone: PropTypes.shape({
        heading: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      }),
      pocgrouptwo: PropTypes.shape({
        heading: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        bgimage={data.desktop.childImageSharp.fluid}
        bannerinfo={frontmatter.bannerinfo}
        heading={frontmatter.heading}
        seo={
          <SEO
            title={`Home | ${data.site.siteMetadata.title}`}
            description={`${data.site.siteMetadata.homeDescription}`}
            type="website"
          />
        }
        description={frontmatter.description}
        descriptionbullets={frontmatter.descriptionbullets}
        contactpanel={frontmatter.contactpanel}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    desktop: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        author
        title
        description
        homeDescription
        contractVehiclesDescription
        newsDescription
        contactDescription
        siteUrl
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image
        bannerinfo {
          cards {
            headline
            text
          }
        }
        heading
        description
        descriptionbullets {
          bullets {
            text
          }
        }
        contactpanel {
          websitelinks {
            text
            url
          }
          identifiers {
            heading
            duns
            cage
          }
          contactdetails {
            heading
            headquarters {
              heading
              street
              citystatezip
              office
            }
            pocgroupone {
              heading
              name
              title
              phone
              email
            }
            pocgrouptwo {
              heading
              name
              title
              phone
              email
            }
          }
        }
      }
    }
  }
`
