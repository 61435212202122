import React from 'react'
import PropTypes from 'prop-types'

const BulletList = ({ bullets, istwocolumns }) => (
  <div>
    {istwocolumns === false ? (
      <ul>
        {bullets.map((item, index) => (
          <li className="is-size-5 " key={item.text}>
            {item.text}
          </li>
        ))}
      </ul>
    ) : (
      <div className="columns">
        <div className="column is-6">
          <ul className="margin-top-0">
            {bullets.slice(0, bullets.length / 2).map((item, index) => (
              <li className="is-size-5 " key={item.text}>
                {item.text}
              </li>
            ))}
          </ul>
        </div>
        <div className="column is-6">
          <ul className="margin-top-0">
            {bullets
              .slice(bullets.length / 2, bullets.length)
              .map((item, index) => (
                <li className="is-size-5 " key={item.text}>
                  {item.text}
                </li>
              ))}
          </ul>
        </div>
      </div>
    )}
  </div>
)

BulletList.propTypes = {
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    })
  ),
  istwocolumns: PropTypes.bool,
}

export default BulletList
