import React from 'react'
import PropTypes from 'prop-types'

const ButtonGroup = ({ buttons }) => (
  <div className="buttons">
    {buttons.map(item => {
      if (item.url.includes('http') || item.url.includes('pdf')) {
        return (
          <a
            key={item.text}
            href={item.url}
            className="button is-medium is-fullwidth is-radiusless"
            target="__blank"
            rel="noopener"
          >
            {item.text}
          </a>
        )
      } else {
        return (
          <a
            key={item.text}
            href={item.url}
            className="button is-medium is-fullwidth is-radiusless"
          >
            {item.text}
          </a>
        )
      }
    })}
  </div>
)

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

export default ButtonGroup
